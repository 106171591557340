<template>
    <div>
      <a href="#" @click.prevent="show" class="w-full">
          <img class="w-64" :src="thumbnail">
      </a>
      <div class="lightbox fixed pin z-50 flex justify-center items-center" v-if="visible" @click="hide">
          <div class="fixed pin-r pin-t text-white cursor-pointer text-4xl p-1 mr-2" @click.stop="hide">&times;</div>
          <div class="flex">
              <div class="cursor-pointer self-center px-8"
                   @click.stop="prev">
                  <svg class="pointer-events-none" fill="#fff" height="48" viewBox="0 0 24 24" width="48" xmlns="http://www.w3.org/2000/svg">
                      <path d="M15.41 16.09l-4.58-4.59 4.58-4.59L14 5.5l-6 6 6 6z"/>
                      <path d="M0-.5h24v24H0z" fill="none"/>
                  </svg>
              </div>
              <div class="lightbox-image" @click.stop="">
                  <img :src="images">
              </div>
              <div class="cursor-pointer self-center px-8"
                   @click.stop="next"
                   >
                  <svg class="pointer-events-none" fill="#fff" height="48" viewBox="0 0 24 24" width="48" xmlns="http://www.w3.org/2000/svg">
                      <path d="M8.59 16.34l4.58-4.59-4.58-4.59L10 5.75l6 6-6 6z"/>
                      <path d="M0-.25h24v24H0z" fill="none"/>
                  </svg>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
export default {
    props: {
        thumbnail: {
            type: String,
            required: true,
        },
        images: {
            type: String,
            default: () => [],
        },
    },
    data() {
        return {
            visible: false,
            index: 0,
        };
    },
    methods: {
        show() {
            this.visible = true;
        },
        hide() {
            this.visible = false;
            this.index = 0;
        },
    },
};
</script>

<style>
.lightbox {
    background: #00000069;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
}

.fixed.pin-r.pin-t.text-white.cursor-pointer.text-4xl.p-1.mr-2 {
    display: flex;
    justify-content: flex-end;
    font-size: 40px;
    cursor: pointer;
}

.cursor-pointer.self-center.px-8 {
    display: none;
}

.lightbox-image img {
    display: flex;
    justify-content: center;
    width: 55%;
    margin: auto;
    border-radius: 3px;
}

</style>