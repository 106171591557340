import Rest from '@/services/Rest';

/**
 * @typedef {TicketService}
 */
export default class TicketService extends Rest {
    /**
     * @type {String}
     */
    static resource = 'ticket'
}